<template>
    <div class="home bg-gray-relative-100">
        <Header></Header>
        <div class="m-3 mt-4">
            <router-link to="/payment" class="bg-bluegray text-white rounded-4 w-100 p-3 shadow-200 flex-between-center">
                <span class="fw-bold fs-px-17">
                    <i class="fas fa-qrcode me-2 text-gray-relative-400"></i>
                    현장결제
                </span>
                <span>
                    <span class="fw-bolder">QR</span>
                    <i class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"></i>
                </span>
            </router-link>
        </div>
        <div class="day_statistics flex-between-center flex-wrap m-3 mt-4">
            <router-link to="/history/today/confirm" class="d-flex flex-column bg-body rounded-4 h-px-120 p-3 shadow-200">
                <div class="fw-bold flex-grow-1 align-self-start">
                    <div class="d-inline-block me-2">
                        <i class="fas fa-clock text-warning"></i>
                        <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-warning"></i> -->
                    </div>
                    <span>오늘 승인내역</span>
                </div>
                <div class="fs-6 fw-bolder text-end">
                    <span><span class="text-main">{{CheckNumber(today_confirm_price)}}</span>원</span>
                    <span class="fs-px-11 mx-1">/</span>
                    <span><span class="text-main">{{CheckNumber(today_confirm_count)}}</span>건</span> 
                    <i class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"></i>
                </div>
            </router-link>
            <router-link to="/history/month/confirm" class="d-flex flex-column bg-body rounded-4 h-px-120 p-3 shadow-200">
                <div class="fw-bold flex-grow-1">
                    <div class="d-inline-block me-2">
                        <i class="fas fa-check-circle text-main"></i>
                        <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-main"></i> -->
                    </div>
                    <span>이번 달 승인내역</span>
                </div>
                <div class="fs-6 fw-bolder text-end">
                    <span><span class="text-main">{{CheckNumber(month_confirm_price)}}</span>원</span>
                    <span class="fs-px-11 mx-1">/</span>
                    <span><span class="text-main">{{CheckNumber(month_confirm_count)}}</span>건</span> 
                    <i class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"></i>
                </div>
            </router-link>
            <router-link to="/history/today/cancel" class="d-flex flex-column bg-body rounded-4 h-px-120 p-3 shadow-200">
                <div class="fw-bold flex-grow-1 align-self-start">
                    <div class="d-inline-block me-2">
                        <i class="fas fa-clock text-warning"></i>
                        <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-warning"></i> -->
                    </div>
                    <span>오늘 취소내역</span>
                </div>
                <div class="fs-6 fw-bolder text-end">
                    <span><span class="text-main">{{CheckNumber(today_cancel_price)}}</span>원</span>
                    <span class="fs-px-11 mx-1">/</span>
                    <span><span class="text-main">{{CheckNumber(today_cancel_count)}}</span>건</span> 
                    <i class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"></i>
                </div>
            </router-link>
            <router-link to="/history/month/cancel" class="d-flex flex-column bg-body rounded-4 h-px-120 p-3 shadow-200">
                <div class="fw-bold flex-grow-1">
                    <div class="d-inline-block me-2">
                        <i class="fas fa-check-circle text-main"></i>
                        <!-- <i class="fas fa-circle fs-px-10 d-inline-block text-main"></i> -->
                    </div>
                    <span>이번 달 취소내역</span>
                </div>
                <div class="fs-6 fw-bolder text-end">
                    <span><span class="text-main">{{CheckNumber(month_cancel_price)}}</span>원</span>
                    <span class="fs-px-11 mx-1">/</span>
                    <span><span class="text-main">{{CheckNumber(month_cancel_count)}}</span>건</span> 
                    <i class="fas fa-chevron-right fs-px-14 ms-2 text-gray-relative-500"></i>
                </div>
            </router-link>
        </div>
        <div class="m-3 mt-4">
            <div class="d-flex flex-column bg-body rounded-4 p-3 shadow-200">
                <div class="flex-between-center mb-2">
                    <h6 class="fw-bolder">거래내역</h6>
                    <router-link class="small text-muted" to="/mypage/history">더보기</router-link>
                </div>
                <div>
                    <ul class="last-border-0" v-if="history&&history.length>0">
                        <li class="px-2 py-3 flex-between-center border-bottom border-gray-relative-200" v-for="(item,index) in history" :key="index" @click="$router.push(`/payment/receipt/${item.code}`)">
                            <div class="flex-between-center">
                                <div class="d-flex flex-column fs-px-13">
                                    <small class="text-gray-relative-500">{{item.date}}<span class="ms-2 badge bg-success" v-if="item.status==='Y'">{{CheckStatus(item.status)}}</span><span class="ms-2 badge bg-danger" v-if="item.status==='C'">{{CheckStatus(item.status)}}</span></small>
                                    <p class="lh-1 fw-bold">
                                    {{ item.payment_name }}</p>
                                </div>
                            </div>
                            <div class="d-flex flex-column price_tag">
                                <small class="text-end text-danger">
                                    {{ item.price.toLocaleString() }}
                                    원
                                </small>
                                <span class="fs-px-12 text-end text-gray-relative-500">
                                    <span>{{item.user_name}} 고객</span>
                                </span>
                            </div>
                        </li>
                    </ul>
                    <div class="p-3 py-4 text-center text-gray-relative-500" v-else>
                        <i class="fal fa-shopping-cart fa-3x text-gray-relative-400 d-block my-3"></i>
                        <p class="my-2 fs-px-13">거래 내역이 없어요.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="m-3 my-4">
            <div class="d-flex flex-column bg-body rounded-4 p-3 shadow-200">
                <div class="flex-between-center mb-2">
                    <h6 class="fw-bolder">정산내역</h6>
                    <router-link class="small text-muted" to="/mypage/calculate">더보기</router-link>
                </div>
                <div>
                    <ul class="last-border-0" v-if="calculate&&calculate.length>0">
                        <li class="pb-3 border-bottom border-gray-relative-200" v-for="(item,index) in calculate" :key="index">
                            <div class="d-flex flex-column">
                                <small class="text-muted fs-px-11 py-1 bg-gray-100 px-3 mb-2">{{ item.calc_date }}</small>
                                <div class="flex-between-center px-3 pt-1">
                                    <div class="d-flex flex-column fs-px-13">
                                        <small class="text-gray-relative-500 mb-1">
                                            <span class="badge bg-success" v-if="item.status==='H'">입금 예정</span>
                                
                                            <span class="badge bg-primary" v-else>입금 완료</span>
                                            <!-- 정산 신청일 -->
                                
                                        </small>
                                        
                                        <span class="fs-px-12 text-end text-gray-relative-500">
                                            <span>{{ CheckNumber(item.price) }}원 <span class="text-danger">- {{CheckNumber(item.fee_price)}}원</span></span>
                                        </span>
                                    </div>
                                    <div class="d-flex flex-column">
                                        <small class="text-end">+
                                            {{ CheckNumber(item.calculate) }}원
                                        </small>
                                        <span class="fs-px-12 text-end text-gray-relative-500">
                                            <span>수수료 <span class="text-danger">{{item.fee}}%</span></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="p-3 py-4 text-center text-gray-relative-500" v-else>
                        <i class="fal fa-calculator fa-3x text-gray-relative-400 d-block my-3"></i>
                        <p class="my-2 fs-px-13">정산 내역이 없어요.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    
const CryptoJS = require("crypto-js");
import Header from '@/components/common/Header.vue'
export default {
    name: 'Home',
    components: {
        Header,
    },
    data() {
        return {
            today_confirm_count : '',
            today_confirm_price : '',
            today_cancel_count : '',
            today_cancel_price : '',
            
            month_confirm_count : '',
            month_confirm_price : '',
            month_cancel_count : '',
            month_cancel_price : '',
            

            history:[],
            calculate:[]
        }
    },
    mounted() {
        this.GetDashboard()
        this.GetPaymentHistory();
        this.GetCalculate();
    },
    methods: {
        CheckStatus(status){
            if(status =='Y'){
                return "승인"
            }else if(status =='C'){
                return "취소"
            }
        },
        CheckNumber(value){
            const amount = value*1;
            return amount.toLocaleString();
        },
        GetCalculate(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/dashboard/GetCalculate', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            this.calculate = body.list;
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        
        GetPaymentHistory(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/dashboard/GetPaymentHistory', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            this.history = body.list;
                            
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
        GetDashboard(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/dashboard/GetDashboard', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            this.today_confirm_count = body.today_confirm.count;
                            this.today_confirm_price = body.today_confirm.price;

                            this.today_cancel_count = body.today_cancel.count;
                            this.today_cancel_price = body.today_cancel.price;

                            this.month_confirm_count = body.month_confirm.count;
                            this.month_confirm_price = body.month_confirm.price;

                            this.month_cancel_count = body.month_cancel.count;
                            this.month_cancel_price = body.month_cancel.price;
                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
    }
}
</script>
<style lang="scss" scoped>
    .home {
        overflow: auto;
        position: relative;
        height: calc(100vh - 70px);
        .day_statistics a{
            width: 48%;
            margin-right: 2%;
            margin-bottom: 2%;
        }
    }

    .bg-bluegray{
        background-color: #545f6e;
    }
    
.price_tag{
    min-width: 30%;
  }
</style>