// axios
import axios from 'axios'
import store from './store';
 
const baseURL = process.env.VUE_APP_API_URL;

// export default axios.create({
//   baseURL: baseURL,
//   // You can add your headers here
//   headers: {
//     Authorization: store.state.token,
//   }
// });

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use((config) => {
  const token = store.state.token;
  
  if (token) {
    config.headers.Authorization = token;
  }

  return config;
});

export default instance;