import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    login:false,
    token:'',
    name : '',
    shop_name : '',
    auth : '',
    theme: 'light',
    is_save_id: false,
    is_auto_login: false,
  },
  plugins: [createPersistedState()],
  mutations: {
    ChgTheme(state, value){
      state.theme = value;
    },
    SetSaveId(state, payload) {
      state.is_save_id = payload;
    },
    SetAutoLogin(state, payload) {
      state.is_auto_login = payload;
    },
    SETLOGIN(state, mem_info) {
      
      state.login = true;
      state.token = mem_info.token;
      state.name = mem_info.name;
      state.auth = mem_info.auth;
      state.shop_name = mem_info.shop_name;

    },
    SETNAME(state, name) {
      state.name = name;

    },
    SETLOGOUT(state) {
      state.token = '';
      state.name = '';
      state.auth = '';
      state.shop_name = '';
      state.login = false;
    },
  },
  actions: {
    SETLOGIN({commit}, {mem_info}) {
      commit('SETLOGIN', mem_info);
    },
    SETNAME({commit}, name) {
      commit('SETNAME', name);
    },
    SETLOGOUT ({commit}) {
      commit('SETLOGOUT');
    },
  }
})
