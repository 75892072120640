<template>
    <div id="Nav" class="position-fixed bottom-0 w-100 bg-body" v-if="show">
        <div class="my-1">
            <ul class="d-flex justify-content-around align-items-center">
                <li><router-link to="/home">
                    <div class="lh-1 fs-5 mb-1">
                        <i class="fal fa-home-alt"></i>
                    </div>
                    <span class="fs-px-10">홈</span>
                </router-link></li>
                <li><router-link to="/payment">
                    <div class="lh-1 fs-5 mb-1">
                        <i class="fal fa-barcode-alt"></i>
                    </div>
                    <span class="fs-px-10">결제</span>
                </router-link></li>
                <li><router-link to="/statistics">
                    <div class="lh-1 fs-5 mb-1">
                        <i class="fal fa-chart-pie"></i>
                    </div>
                    <span class="fs-px-10">통계</span>
                </router-link></li>
                <li><router-link to="/mypage">
                    <div class="lh-1 fs-5 mb-1">
                        <i class="fal fa-user-circle"></i>
                    </div>
                    <span class="fs-px-10">마이페이지</span>
                </router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default { 
    data(){
        return{
            show: true,
        }
    },
    created() {
        this.$EventBus.$on('HideNav', () => {
            this.show = false;
            document.getElementById("app").classList.add("hide_nav");
        }),
        this.$EventBus.$on('ShowNav', () => {
            this.show = true;
            document.getElementById("app").classList.remove("hide_nav");
        })
    },
}
</script>

<style lang="scss" scoped>
    #Nav{
        box-shadow: 0 0px 20px rgba(125, 125, 125, 0.1);
        max-width: 768px;
        z-index: 99;
        // max-width: 100vw;

        ul{
            height: 60px;

            li{
                width: 20%;
                line-height: 1;
                text-align: center;

                a{
                    opacity: 0.4;
                }

                a.router-link-active{
                    opacity: 1;
                }

                .home_icon{
                    top: -50px;
                    color: #fff !important;
                        background: #11998e;
                        background: linear-gradient(180deg, #38ef7d, #11998e);
                }
            }
        }
        
    }
</style>