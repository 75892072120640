
export default{
    install(Vue){
        Vue.prototype.$copy = function(txt) {
            const textarea = document.createElement('textarea');
            textarea.value = txt;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);

            Vue.prototype.$alert('복사되었습니다.')
        };
    }
}