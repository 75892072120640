<template>
  <Header class="py-2 bg-body shadow-200">
    <div class="flex-between-center">
        <div class="ps-3 profile fs-6 fw-bold">
            <span class="badge me-2 py-2"><i class="fal fa-home-lg fa-sm"></i></span>
            <span class="">{{ name }}</span>
        </div>
        <div class="menu pe-2">
            <button class="btn border-0 position-relative" @click="$refs.PushPopup.pp = true">
                <i class="fas fa-bell text-muted"></i>
                <span class="position-absolute badge rounded-pill bg-danger fs-px-10" v-if="total > 0">{{ total }}</span>
            </button>
            <button class="btn border-0" @click="$router.push('/mypage')"><i class="fas fa-user-circle text-muted"></i></button>
           
        </div>
    </div>
    <PushPopup ref="PushPopup" />
  </Header>
</template>

<script>
const CryptoJS = require("crypto-js");
import PushPopup from '@/components/popup/Push.vue'

export default {
    components:{
        PushPopup
    },
    data(){
        return{
            name:this.$store.state.shop_name,
            login:this.$store.state.login,
            total :0,
        }
    },
    mounted(){
        if(this.login ==false){
            this.$router.push({path:'/signin'})
        }else{
            this.ShopNotice()
        }
        
    },
    methods:{
        ShopNotice(){
            const body = {}
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/shop/common/GetTotalPush', {req}).then(  
                res => {
                    if(res.status ==200){
                        if(res.data.code=='200'){
                            
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res);  

                            this.total = body.info.total;

                        }else if(res.data.code=='9999'){
                            this.$store.dispatch('SETLOGOUT').then(
                                ()=>{
                                    window.location.href="/signin";
                                }
                            )
                        }
                    }
                }
            )
        },
    

    }
}
</script>

<style lang="scss" scoped>
.profile{
    .badge{
        background-color: skyblue;
    }
}
.btn{
    .badge{
        top: 2px;
        right: 2px;
    }
}
</style>

</style>