<template>
    <div class="alert-container position-fixed top-0 start-0">
        <div class="dimm w-100 h-100 bg-body-i bg-opacity-50 position-absolute top-0 start-0"></div>
        <div class="content position-absolute top-50 start-50 bg-body bg-opacity-85 backdrop-blur-10 rounded-4 text-center">
            <div class="inner p-3">
                <div v-html="msg"></div>
            </div>
            <div class="hr"></div>
            <div class="border-top border-gray-relative-500 flex-center-center">
                <button class="btn p-2 flex-grow-1 text-main" @click="ok()">확인</button>
                <span class="vr bg-gray-relative-600"></span>
                <button class="btn p-2 flex-grow-1 text-body" v-if="confirm" @click="deny()">취소</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        idx: {
            type: Number
        },
        msg: {
            type: String
        },
        confirm: {
            type: Boolean,
            default: false
        },
    },
    methods:{
    },
}
</script>

<style lang="scss" scoped>
    .alert-container{
        width: 100vw;
        height: 100vh;
        z-index: 999;

        .dimm{
            animation: fadeIn 0.2s;
        }
        .content{
            min-width: 270px;
            transform-origin: 0 0;
            animation: zoomin 0.2s;
            animation-fill-mode: forwards;
            
            .border-top{
                border-width: 0.5px !important;
            }
        }
    }
    @keyframes fadeIn{
        from { opacity: 0; }
        to { opacity: 1; }
    }
    @keyframes zoomin{
        from { transform: scale(0.8) translate(-50%, -50%); }
        to { transform: scale(1) translate(-50%, -50%); }
    }
</style>