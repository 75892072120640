import AlertTemp from '@/components/other/AlertTemp.vue';

export default{
    install(Vue){ 
        let alert_list = [];
        
        function createAlertInstance(msg, confirm, okCallback, denyCallback) {
            let idx = alert_list.length > 0 ? alert_list.length : 0;
            const AlertComponent = Vue.extend(AlertTemp);
            const AlertInstance = new AlertComponent({
                propsData: {
                    idx: idx,
                    msg: msg,
                    confirm: confirm
                },
                methods: {
                    ok() {
                        okCallback && okCallback();
                        alert_off(idx);
                    },
                    deny() {
                        denyCallback && denyCallback();
                        alert_off(idx);
                    }
                }
            });
            alert_list.push(AlertInstance);
            AlertInstance.$mount();
            document.querySelector('#app').appendChild(AlertInstance.$el);
        }

        
        Vue.prototype.$alert = function(msg) {
            return new Promise((resolve, reject) => {
                createAlertInstance(msg, false, resolve);
            });
        };
          
        Vue.prototype.$confirm = function(msg) {
            return new Promise((resolve, reject) => {
                createAlertInstance(msg, true, () => resolve({isOk: true}), () => resolve({isDeny: true}));
            });
        };
  
        function alert_off(idx){
            alert_list[idx].$destroy();
            alert_list[idx].$el.remove();
            alert_list.splice(idx, 1);
        }
    }
}
