import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/signin',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
  },
  {
    path: '/search',
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/search/Search.vue'),
    children: [
      {
        path: '/search/domestic',
        name: 'Domestic',
        component: () =>
          import(
            /* webpackChunkName: "search" */ '../views/search/Domestic.vue'
          ),
      },
      {
        path: '/search/oversea',
        name: 'Oversea',
        component: () =>
          import(
            /* webpackChunkName: "search" */ '../views/search/Oversea.vue'
          ),
      },
    ],
  },
  {
    path: '/term/:idx',
    name: 'Terms',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/TermsDetail.vue'
      ),
  },
  // 마이페이지
  {
    path: '/mypage',
    name: 'Mypage',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Home.vue'),
  },
  {
    path: '/mypage/history',
    name: 'History',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/History.vue'),
  },
  {
    path: '/myinfo',
    name: 'MyInfo',
    component: () =>
      import(/* webpackChunkName: "MyInfo" */ '../views/mypage/MyInfo.vue'),
  },
  {
    path: '/mypage/calculate',
    name: 'Calculate',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Calculate.vue'),
  },
  {
    path: '/mypage/giftcard',
    name: 'GiftCard',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/GiftCard.vue'),
  },
  {
    path: '/mypage/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Profile.vue'),
  },
  {
    path: '/mypage/item',
    name: 'ITEM',
    component: () =>
      import(/* webpackChunkName: "mypageitem" */ '../views/mypage/Item.vue'),
  },
  {
    path: '/mypage/setting',
    name: 'Setting',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Setting.vue'),
  },
  {
    path: '/opt-out',
    name: 'OptOut',
    component: () =>
      import(/* webpackChunkName: "shop" */ '../views/mypage/OptOut.vue'),
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () =>
      import(
        /* webpackChunkName: "statistics" */ '../views/statistic/Statistics.vue'
      ),
  },

  {
    path: '/bookmarks',
    name: 'Bookmarks',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Bookmarks.vue'),
  },
  {
    path: '/franchisee',
    name: '매장관리',
    component: () =>
      import(
        /* webpackChunkName: "mypagefranchisee" */ '../views/mypage/Franchisee.vue'
      ),
  },
  {
    path: '/manager',
    name: '관리자관리',
    component: () =>
      import(
        /* webpackChunkName: "mypagemanager" */ '../views/mypage/Manager.vue'
      ),
  },

  // 고객센터
  {
    path: '/cs/inquiry/write',
    name: 'CsInquiryWrite',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/InquiryWrite.vue'),
  },
  {
    path: '/cs/inquiry/list',
    name: 'CsInquiryList',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/InquiryList.vue'),
  },
  {
    path: '/cs/faq',
    name: 'CsFaq',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Faq.vue'),
  },
  {
    path: '/cs/notice',
    name: 'CsNotice',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/Notice.vue'),
  },
  {
    path: '/cs/notice/:code',
    name: 'CsNoticeDetail',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/NoticeDetail.vue'),
  },

  // 로그인&회원가입
  {
    path: '/signin',
    name: 'SignIn',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/SignIn.vue'),
  },
  {
    path: '/find-pw',
    name: 'FindPw',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/FindPw.vue'),
  },
  {
    path: '/find-id',
    name: 'FindId',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/FindId.vue'),
  },

  // ========================가맹점=================================

  {
    path: '/payment',
    name: 'PaymentForm',
    component: () =>
      import(
        /* webpackChunkName: "payment" */ '../views/payment/PaymentForm.vue'
      ),
  },
  {
    path: '/coupon/payment/:code',
    name: 'CouponPaymentCode',
    component: () =>
      import(
        /* webpackChunkName: "Couponpayment" */ '../views/payment/CouponPayment.vue'
      ),
  },
  {
    path: '/point/payment/:code',
    name: 'PointPaymentCode',
    component: () =>
      import(
        /* webpackChunkName: "PointPayment" */ '../views/payment/PointPayment.vue'
      ),
  },
  {
    path: '/payment/receipt/:code',
    name: 'PaymentReceipt',
    component: () =>
      import(
        /* webpackChunkName: "payment" */ '../views/payment/PaymentReceipt.vue'
      ),
  },

  {
    path: '/terms/:idx',
    name: 'TermsDetail',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/mypage/TermsDetail.vue'),
  },
  {
    path: '/user/:idx',
    name: 'UserDetail',
    component: () =>
      import(/* webpackChunkName: "customer" */ '../views/cs/UserDetail.vue'),
  },
  {
    path: '/reservation/wait',
    name: 'ReservationWait',
    component: () =>
      import(
        /* webpackChunkName: "reserve" */ '../views/reservation/ReservationWait.vue'
      ),
  },
  {
    path: '/reservation/payment/:code',
    name: 'ReservationPayment',
    component: () =>
      import(
        /* webpackChunkName: "reservePayment" */ '../views/reservation/ReservationPayment.vue'
      ),
  },
  {
    path: '/reservation/edit/:code',
    name: 'ReservationPaymentEdit',
    component: () =>
      import(
        /* webpackChunkName: "reservePayment" */ '../views/reservation/ReservationPaymentEdit.vue'
      ),
  },
  {
    path: '/reservation/cancel',
    name: 'ReservationCancel',
    component: () =>
      import(
        /* webpackChunkName: "reserve" */ '../views/reservation/ReservationCancel.vue'
      ),
  },
  {
    path: '/reservation/confirm',
    name: 'ReservationConfirm',
    component: () =>
      import(
        /* webpackChunkName: "reserve" */ '../views/reservation/ReservationConfirm.vue'
      ),
  },
  {
    path: '/reservation/detail/:code',
    name: '',
    component: () => import('../views/reservation/ReservationDetail.vue'),
  },
  {
    path: '/reservation/history/:date',
    name: 'ReservationHistory',
    component: () =>
      import(
        /* webpackChunkName: "reserve" */ '../views/reservation/ReservationHistory.vue'
      ),
  },
  {
    path: '/history/today/confirm',
    name: 'HistoryTodayConfirm',
    component: () =>
      import(
        /* webpackChunkName: "historyTodayConfirm" */ '../views/history/TodayConfirm.vue'
      ),
  },
  {
    path: '/history/today/cancel',
    name: 'HistoryTodayCancel',
    component: () =>
      import(
        /* webpackChunkName: "historyTodayCancel" */ '../views/history/TodayCancel.vue'
      ),
  },
  {
    path: '/history/month/confirm',
    name: 'HistoryMonthConfirm',
    component: () =>
      import(
        /* webpackChunkName: "historyMonthConfirm" */ '../views/history/MonthConfirm.vue'
      ),
  },
  {
    path: '/history/month/cancel',
    name: 'HistoryMonthCancel',
    component: () =>
      import(
        /* webpackChunkName: "historyMonthCancel" */ '../views/history/MonthCancel.vue'
      ),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
